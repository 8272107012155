
import { SyneriseUid } from '../../../modules/ProductsTeaser/ProductsTeaser.contracts'

import { DynamicContentData } from '../contracts/dynamicContent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @param link
 * @param isUserPremium
 * @param dynamicContentData
 */
export const getUrlWithSyneriseCampaign = (
  link: string,
  isUserPremium: boolean,
  dynamicContentData: DynamicContentData
): string => {
  const productPath = link || ''
  const url = productPath.split('?')[0]
  const params = productPath.split('?')[1]
  return `${url}?snrai_campaign=${isUserPremium ? (dynamicContentData?.recommendationIds as SyneriseUid).premium : (dynamicContentData?.recommendationIds as SyneriseUid).generic}${params ? '&' + params : ''}`
}
