// Copyright © 2021 Move Closer

import { Component, Vue } from 'vue-property-decorator'

import { AllowedAttributes } from '../../../contexts'
import { Inject, logger } from '../../../support'

import { IProductsRepository, ProductsRepositoryType } from '../../products/contracts/repositories'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl >
 */
@Component<GiftHelperMixin>({ name: 'GiftHelperMixin' })
export class GiftHelperMixin extends Vue {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  /**
   * Checks whether every possible gifts are available
   * @protected
   */
  protected async checkGiftsAvailability (currentVariant: unknown): Promise<boolean> {
    if (!currentVariant) {
      return false
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - currentVariant as unknown
    const possibleGifts = currentVariant.attributes[AllowedAttributes.GiftsSku] as string[]

    if (!possibleGifts || possibleGifts.length === 0) {
      return false
    }

    try {
      const gifts = await this.productsRepository.loadProductsBySkus(possibleGifts)

      if (!gifts || gifts.length === 0) {
        return false
      }

      return gifts.every((gift) => Object.values(gift.variants)[0].isAvailable && Object.values(gift.variants)[0].sellableQuantity > 0)
    } catch (e) {
      logger(e, 'warn')
      return false
    }
  }
}

export default GiftHelperMixin
